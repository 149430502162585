<template>
    <div class="field">
        <label class="label"
               :for="id">{{ label }}</label>

        <div class="input-wrapper">
            <vue-multiselect
                id="countries"
                :options="countries"
                v-model="selectedCountryPrefix"
                :allow-empty="false"
                :searchable="false"
                :disabled="disabled">
                <template slot="singleLabel"
                          slot-scope="props">
                    <img :src="'/svg/icons/flags/' + props.option.code + '.svg'"
                         alt="country flag">
                    <span v-text="props.option.prefix" />
                </template>
                <template slot="option"
                          slot-scope="props">
                    <div class="option__desc">
                        <img :src="'/svg/icons/flags/' + props.option.code + '.svg'"
                             alt="country flag">
                        <span class="option__title">{{ props.option.prefix }}</span>
                    </div>
                </template>
            </vue-multiselect>

            <input :id="id"
                   class="phone-input"
                   :value="phoneNumber"
                   type="text"
                   :disabled="disabled"
                   @input="formatPhoneNumber($event.target.value)">
        </div>
    </div>
</template>

<script>
    import VueMultiselect from 'vue-multiselect';
    import { parsePhoneNumber, AsYouType, CountryCode, isValidPhoneNumber } from 'libphonenumber-js';

    export default {
        components: {
            VueMultiselect,
        },
        props: {
            id: {
                type: String,
                default: '',
            },
            label: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                selectedCountryPrefixRaw: null,
                phoneNumber: '',
                internationalizedPhoneNumber: '',
                countries: [
                    {
                        prefix: '+31',
                        code: 'nl',
                    },
                    {
                        prefix: '+32',
                        code: 'be',
                    },
                    {
                        prefix: '+33',
                        code: 'fr',
                    },
                    {
                        prefix: '+34',
                        code: 'es',
                    },
                    {
                        prefix: '+36',
                        code: 'hu',
                    },
                    {
                        prefix: '+40',
                        code: 'ro',
                    },
                    {
                        prefix: '+41',
                        code: 'ch',
                    },
                    {
                        prefix: '+44',
                        code: 'gb',
                    },
                    {
                        prefix: '+48',
                        code: 'pl',
                    },
                    {
                        prefix: '+49',
                        code: 'de',
                    },
                    {
                        prefix: '+359',
                        code: 'bg',
                    },
                    {
                        prefix: '+370',
                        code: 'li',
                    },
                    {
                        prefix: '+371',
                        code: 'lv',
                    },
                    {
                        prefix: '+373',
                        code: 'md',
                    },
                ],
            };
        },
        methods: {
            formatPhoneNumber(value) {
                /** @type {CountryCode} */
                const upperCasedCountryCode = this.selectedCountryPrefix.code.toUpperCase();

                this.phoneNumber = new AsYouType(upperCasedCountryCode).input(value);

                this.$emit('phoneNumber', '');

                if (isValidPhoneNumber(this.phoneNumber, upperCasedCountryCode)) {
                    this.internationalizedPhoneNumber = parsePhoneNumber(this.phoneNumber, upperCasedCountryCode).formatInternational();
                    this.$emit('phoneNumber', this.internationalizedPhoneNumber);
                }
            },

            stripPrefix(value) {
                if (!value) {
                    return;
                }

                return value.replace(this.selectedCountryPrefix.prefix, '');
            },
        },
        watch: {
            selectedCountryPrefix: {
                immediate: true,
                deep: true,
                handler() {
                    this.formatPhoneNumber(this.phoneNumber);
                },
            },
            value: {
                immediate: true,
                handler(value) {
                    if (value) {
                        this.phoneNumber = this.stripPrefix(value);
                        this.formatPhoneNumber(this.phoneNumber);
                    }
                },
            },
        },
        computed: {
            selectedCountryPrefix: {
                get() {
                    const country = this.countries.find((country) => this.value?.startsWith(country.prefix));

                    return this.selectedCountryPrefixRaw || country || {
                        prefix: '+31',
                        code: 'nl',
                    };
                },
                set(value) {
                    this.selectedCountryPrefixRaw = value;
                },
            },
        },
    };
</script>

<style lang="scss" scoped>
    .input-wrapper {
        display     : flex;
        align-items : center;
        gap         : 10px;

        ::v-deep .multiselect {
            flex-grow : 0;
            width     : auto;

            .multiselect__select {
                top : 5px;
            }

            .multiselect__tags {
                min-height : 52px;

                .multiselect__single {
                    display     : flex;
                    align-items : center;
                    gap         : 5px;
                }
            }

            .multiselect__content-wrapper {
                border           : 1px solid var(--color-grey-100);
                background-color : var(--color-white);

                .multiselect__content {
                    .multiselect__option {
                        color : var(--color-black);

                        .option__desc {
                            align-items : center;
                            gap         : 5px;
                        }
                    }

                    .multiselect__option--highlight,
                    .multiselect__option--selected {
                        background-color : var(--color-grey-100);

                        &::after {
                            display : none;
                        }
                    }
                }
            }
        }

        .phone-input {
            margin-top : 0;
        }
    }
</style>
